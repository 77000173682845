<script setup>
import { ref, computed, onBeforeUnmount, onMounted } from 'vue'
import { useStore } from '@/stores'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import CartSummary from './CartSummary.vue'
import EstablishmentStatusMessage from './StatusMessage.vue'
import CheckoutButton from '@/components/Buttons/CheckoutButton.vue'
import LoadingSpinner from '@/components/partials/Loading/LoadingSpinner.vue'
import CartItem from './CartItem.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import { useBasket } from '@/composables/basket'

const { t } = useI18n()
const store = useStore()
const route = useRoute()

const itemsContainer = ref(null)
const hasScrollbar = ref(false)

const emit = defineEmits(['confirm', 'openInstructionsModal'])

const establishment = computed(() => store.getters['cart/establishment'])
const hasItemsFromEstablishment = computed(() => store.getters['cart/hasItemsFromEstablishment'])
const basketInfo = computed(() => store.getters['cart/basketInfo'])
const hasBasketInfo = computed(() => store.getters['cart/hasBasketInfo'])
const basketItems = computed(() => store.getters['cart/basketItems'])
const cartIsSyncing = computed(() => store.getters['cart/isSyncing'])
const cartEstablishmentTitle = computed(() => establishment.value?.title ?? basketInfo.value?.establishment?.name)

const props = defineProps({
  noButton: Boolean,
  noStatusMessage: Boolean,
  noGradientTitle: Boolean,
})

const laterDate = computed(() => {
  const isLaterDate = store.getters['cart/isLaterDate']

  if (!isLaterDate) return null

  const timeSlot = store.getters['cart/distributionDateText']

  return timeSlot
})

const openEmptyCartConfirmationModal = () => {
  store.dispatch('modal/show', {
    closable: true,
    dataTestId: 'empty-cart-confirmation-modal',
    title: t('confirmation-modal.title'),
    message: t('confirmation-modal.message-empty', {
      establishment: store.getters['cart/establishment'].name,
    }),
    callback: [
      {
        label: t('confirmation-modal.cancel-btn'),
        dataTestId: 'empty-cart-confirmation-modal-cancel',
        action: () => {
          store.dispatch('modal/reset')
        },
        properties: {
          type: 'secondary',
        },
      },
      {
        label: t('confirmation-modal.confirm-btn'),
        dataTestId: 'empty-cart-confirmation-modal-confirm',
        action: async () => {
          await emptyBasket()
          await store.dispatch('modal/reset')
        },
      },
    ],
  })
}

const allProductsOfEstablishment = computed(() => establishment.value?.productSets?.reduce((acc, productSet) => ([
  ...acc,
  ...productSet.products,
]), []) ?? [])

const items = computed(() => basketItems.value?.items.map((item) => {
  if (item == undefined) return

  const fromProductSet = allProductsOfEstablishment.value?.find(({ id }) => id === item.product.id)

  return {
    ...item,
    image: fromProductSet?.images?.cover?.sources?.small?.url,
  }
}))

const allHaveImages = computed(() => items.value.reduce((bool, item) => bool && !!item.image, true))

const withCartOnCheckoutPage = computed(() => store.getters['cart/hasCart'] && route.name.includes('checkout'))

const hasCartFromDifferentEstablishment = computed(() => hasBasketInfo.value && !hasItemsFromEstablishment.value)

const hasCartFromThisEstablishment = computed(() => hasBasketInfo.value && hasItemsFromEstablishment.value)

const basket = useBasket()

async function emptyBasket() {
  const establishment = store.getters['cart/establishment']

  await basket.emptyBasket({
    establishment: {
      id: establishment.id,
      title: establishment.title,
    },
  })
}

const { changeProductAmountInBasket } = useBasket()

async function changeAmount({ hash, amount }) {
  const items = basketItems.value?.items
  const willEmptyBasket = items?.length === 1 && amount === 0

  // do not show modal if basket will not be emptied or on establishment page
  if (!willEmptyBasket) {
    await changeProductAmountInBasket(hash, amount)
    return
  }

  // On establishment pages, do not show an empty basket confirm modal
  if (route.name.includes('establishment')) return emptyBasket()

  // Wait for confirmation
  // This will modal also empty the basket
  openEmptyCartConfirmationModal('empty')
}

const checkScrollbar = () => {
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll' // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps
  document.body.appendChild(outer)

  // Creating inner element and placing it in the container
  const inner = document.createElement('div')
  outer.appendChild(inner)

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth)

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer)

  hasScrollbar.value = itemsContainer.value?.scrollHeight > itemsContainer.value?.clientHeight && scrollbarWidth > 0
}

let resizeObserver = null

onMounted(() => {
  if (itemsContainer.value !== null) {
    resizeObserver = new ResizeObserver(checkScrollbar)
    resizeObserver.observe(itemsContainer.value)
  }
})

onBeforeUnmount(() => {
  if (resizeObserver !== null) {
    resizeObserver.disconnect()
    resizeObserver = null
  }
})

function openInstructionsModal(product) {
  emit('openInstructionsModal', product)
}
</script>

<template>
  <div ref="cartElement"
       :class="[ 'cart', { 'cart--no-gradient-title': props.noGradientTitle, 'cart--has-scrollbar': hasScrollbar } ]"
       data-test-id="cart-desktop">
    <template v-if="hasCartFromThisEstablishment || withCartOnCheckoutPage">
      <div class="cart__content">
        <EstablishmentStatusMessage v-if="establishment !== null && establishment.overlay.overlay !== null && !laterDate"
                                    :text="establishment.overlay.overlay" />

        <div class="cart__title">
          {{ t('cart.shopping_cart') }}
          <LoadingSpinner v-if="cartIsSyncing"
                          class="cart__spinner"
                          :size="16"
          />
        </div>

        <div ref="itemsContainer"
             class="cart__items"
        >
          <CartItem v-for="item in items"
                    :key="item.id"
                    v-bind="item"
                    class="cart__item"
                    :with-image="allHaveImages"
                    @change-amount="changeAmount"
                    @add-instructions="openInstructionsModal(item.product)"
          />
        </div>

        <CartSummary class="cart__summary" />
      </div>

      <div v-if="!noButton"
           class="cart__chin"
      >
        <CheckoutButton v-taptic="'medium'"
                        data-test-id="sidebar-checkout-button"
                        @click="emit('confirm')"
        />
      </div>
    </template>
    <template v-else-if="hasCartFromDifferentEstablishment">
      <div class="cart__empty-basket-message">
        <div>
          <div v-if="establishment" class="cart__empty-basket-message__title">
            {{ t('cart.swapping_restaurants') }}
          </div>
          <div class="cart__empty-basket-message__subtitle">
            {{ t('cart.cart_will_be_emptied', { establishment: cartEstablishmentTitle }) }}
          </div>
          <div class="cart__empty-basket-message__button">
            <Button @click="emptyBasket">
              {{ t('cart.empty_cart') }}
            </Button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="cart__content">
        <div class="cart__title">
          {{ t('cart.shopping_cart') }}
          <LoadingSpinner v-if="cartIsSyncing"
                          class="cart__spinner"
                          :size="16"
          />
        </div>
        {{ t('cart.your_shopping_cart_is_empty') }}
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/styling.scss';
@import '@/assets/css/mixins/breakpoints-up.scss';

.cart {
  $self: &;
  @include shadow-md;
  border: 1px solid #DEE0E4;
  border-radius: 1rem;
  align-self: flex-start;
  position: relative;

  &__content {
    padding: 1.5rem;
  }

  &__empty-basket-message {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    &__title {
      font-weight: 700;
      font-size: 1.25rem;
      width: max-content;
    }

    &__subtitle {
      margin-top: 0.5rem;
    }

    &__button {
      margin-top: 1.5rem;
    }
  }

  &__title {
    @include gradient-text;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 150%;
    width: max-content;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-right: -1.5rem;
    padding: 1.5rem 1.5rem 2rem 0;
    align-items: stretch;
    max-height: calc(100vh - 34rem);
    overflow-y: auto;
    overflow-x: hidden;

    @include lg-up{
      min-height: 12rem;
    }
  }

  &__chin {
    background-color: #F8F8FA;
    padding: 1.5rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    &:deep(.checkout-btn) {
      width: max-content;
      margin: 0 auto;
    }
  }

  &--no-gradient-title {
    #{$self}__title {
      color: var(--color-neutral-primary);
      -webkit-text-fill-color: var(--color-neutral-primary);
      background: transparent;
      background-clip: content-box;
    }
  }

  &--has-scrollbar {
    #{$self}__items {
      padding: 1.5rem 0.5rem 2rem 0;
      position: relative;
    }
    #{$self}__summary {
      padding-top: 1rem;
    }
  }
}
</style>
