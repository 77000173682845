<template>
  <div>
    <textarea v-taptic="'selection'"
              type="text"
              class="textarea"
              :name="name"
              :class="{ 'textarea--error': error !== null, 'textarea--grow': grow }"
              :placeholder="placeholder"
              :value="modelValue"
              :data-test-id="dataTestId"
              @input="(e) => $emit('update:modelValue', e.target.value)"
    />

    <span v-if="error !== null"
          class="textarea__message"
    > {{ error }} </span>
  </div>
</template>

<script>
export default {
  name: 'TextArea',

  props: {
    name: {
      type: String,
      default: `area_${Math.floor(Math.random() * 100 + 1)}`,
    },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    modelValue: { type: String, default: '' },
    dataTestId: { type: String, default: null },
    error: { type: String, default: null },
    grow: Boolean,
  },
  
  emits: ['update:modelValue']
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';

.textarea {
  padding: 0.75rem 2rem 0.75rem 1rem;
  margin: 0;
  border: 1px solid var(--color-neutral-quaternary);
  border-radius: 0.5rem;
  line-height: 1.4;
  width: 100%;
  max-width: 100%;
  height: 10rem;

  @include lg-up {
    width: 32rem;
  }

  &--error {
    border-color: #e4a6a6;
  }

  &__message {
    font-size: 0.9rem;
    color: #e43e3e;
    display: block;
    margin-top: 0.5rem;
  }

  &--grow {
    @include lg-up {
      width: 100%;
    }
  }
}
</style>
