<template>
  <Button
    class="checkout-btn"
    :disabled="!enabled"
    :type="!enabled ? 'secondary' : null"
    :fluid="onEstablishmentPage"
    @click="enabled ? $emit('click') : null"
  >
    {{ label }}
  </Button>
</template>

<script>
import { mapGetters } from 'vuex'
import { useI18n } from 'vue-i18n'
import Button from '@/components/partials/Buttons/Button/Button.vue'

export default {
  name: 'CheckoutButton',

  components: { Button },

  props: {
    mobile: { type: Boolean, default: false },
  },

  emits: ['click'],

  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },

  data() {
    return { variant: null }
  },

  computed: {
    ...mapGetters({
      hasCart: 'cart/hasCart',
      basketInfo: 'cart/basketInfo',
      hasBasketInfo: 'cart/hasBasketInfo',
      establishment: 'establishment/establishment',
    }),

    enabled() {
      return (
        this.hasCart
        && this.hasBasketInfo
        && this.basketInfo?.buttons.checkout.enabled
        && this.establishment?.overlay?.canOrder
      )
    },

    label() {
      if (!this.establishment?.overlay?.canOrder) {
        return this.t('checkout-button.closed')
      }

      // In case the basket has items, but does not meet the minimum amount to order
      if (this.hasBasketInfo && !this.enabled)
        return this.$isMobile()
          ? this.basketInfo?.buttons.checkout.text_short
          : this.basketInfo?.buttons.checkout.text

      // On the establishment page, let the user know it will go to the cart
      if (this.$route.name.includes('establishment')) {
        return this.t('checkout-button.to-checkout')
      }

      // The order is either ready to be completed or the cart is empty
      // The button is either disabled or enabled
      return this.t('checkout-button.to-payment-provider')
    },

    onEstablishmentPage() {
      return this.$route.name.includes('establishment')
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/mixins/breakpoints-down.scss';

.checkout-btn.button {
  @include lg-down {
    width: 100%;
  }

  &:deep(.button__content) {
    min-width: 16rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
}
</style>
