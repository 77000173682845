<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from '@/stores'
import Message from '../components/FreeDeliveryMessage.vue'
import { DistributionType } from '@/http/models/Establishment'

const store = useStore()

const isDelivery = computed(() => store.getters['session/distributionType'] === DistributionType.Delivery)

const hasFreeDelivery = computed(() => !!store.getters['cart/establishment'].freeDeliveryStartingAt)

const due = computed(() => store.getters['cart/establishment'].freeDeliveryStartingAt - store.getters['cart/basketInfo'].costs.subtotal)
</script>

<template>
  <div class="free-delivery-notice-container" v-if="isDelivery && hasFreeDelivery">
    <Message :due="due" />
  </div>
</template>

<style lang="scss" scoped>
.free-delivery-notice-container {
  &:deep(.free-delivery-notice) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem;
    background-color: #f7f7f7;
  }
}
</style>
