<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { formatPrice } from '@/plugins/globals/filters'
import DistributionTypes from '@/config/DistributionTypes'

const store = useStore()

const distributionType = computed(() => store.getters['session/distributionType'])
const basketInfo = computed(() => store.getters['cart/basketInfo'])

const showTip = computed(() => basketInfo.value.costs.restaurant_tip > 0)

const { t } = useI18n()
</script>

<template>
  <div class="cart-summary"
       data-test-id="cart-summary"
       v-if="basketInfo && basketInfo.costs">
    <div class="cart-summary__line" data-test-id="cart-summary-sub-total">
      <div class="cart-summary__text">
        <span class="bold">
          {{ t("cart.sub-total") }}
        </span>
      </div>

      <div class="cart-summary__text">
        <span class="bold">
          {{ formatPrice(basketInfo.costs.subtotal) }}
        </span>
      </div>
    </div>

    <div v-if="basketInfo.costs.total_order_discount > 0"
         class="cart-summary__line"
         data-test-id="cart-summary-discount"
    >
      <div class="cart-summary__text">
        {{ t('receipt.discount') }}
      </div>
      <div class="cart-summary__text">
        <span class="gradient bold">
          {{ formatPrice(basketInfo.costs.total_order_discount) }}
        </span>
      </div>
    </div>

    <div v-if="basketInfo.costs.coupon_has_discount"
         class="cart-summary__line"
    >
      <div class="cart-summary__text">
        {{ t("cart.coupon") }}
      </div>
      <div class="cart-summary__text">
        <span class="gradient bold">
          {{ formatPrice(basketInfo.costs.coupon_discount) }}
        </span>
      </div>
    </div>

    <div v-if="basketInfo.costs.deducted_by_giftcard > 0"
         class="cart-summary__line"
         data-test-id="cart-summary-giftcard"
    >
      <div class="cart-summary__text">
        {{ t("cart.giftcard") }}
      </div>
      <div class="cart-summary__text">
        {{ formatPrice(basketInfo.costs.deducted_by_giftcard) }}
      </div>
    </div>

    <div v-if="distributionType === DistributionTypes.Delivery"
         class="cart-summary__line"
         data-test-id="cart-summary-delivery-costs">
      <div class="cart-summary__text">
        {{ t('terms.delivery') }}
      </div>
      <div class="cart-summary__text">
        <template v-if="basketInfo.costs.delivery_costs_after_discount === 0">
          <span class="gradient bold">
            {{ t('terms.free') }}
          </span>
        </template>
        <template v-else>
          {{ formatPrice(basketInfo.costs.delivery_costs_after_discount) }}
        </template>
      </div>
    </div>

    <div class="cart-summary__line" data-test-id="cart-summary-service-fee">
      <div class="cart-summary__text">
        {{ t('cart.service-fee') }}
      </div>
      <div class="cart-summary__text">
        {{ formatPrice(basketInfo.costs.service_fee) }}
      </div>
    </div>

    <div class="cart-summary__line"  v-if="basketInfo.costs.transaction_fee > 0">
      <div class="cart-summary__text">
        {{ t('cart.transaction-fee') }}
      </div>
      <div class="cart-summary__text">
        {{ formatPrice(basketInfo.costs.transaction_fee) }}
      </div>
    </div>

    <div class="cart-summary__line" v-if="basketInfo.costs.total_sup_surcharge > 0">
      <div class="cart-summary__text">
        {{ t('cart.sup-fee') }}
      </div>
      <div class="cart-summary__text">
        {{ formatPrice(basketInfo.costs.total_sup_surcharge) }}
      </div>
    </div>
    <div class="cart-summary__line" v-if="basketInfo.costs.total_deposit_surcharge > 0">
      <div class="cart-summary__text">
        {{ t('cart.deposit-fee') }}
      </div>
      <div class="cart-summary__text">
        {{ formatPrice(basketInfo.costs.total_deposit_surcharge) }}
      </div>
    </div>
    <div class="cart-summary__line" data-test-id="cart-summary-tip" v-if="showTip">
      <div class="cart-summary__text">
        {{ t('cart.tip') }}
      </div>
      <div class="cart-summary__text">
        {{ formatPrice(basketInfo.costs.restaurant_tip) }}
      </div>
    </div>

    <div class="cart-summary__line" data-test-id="cart-summary-total">
      <div class="cart-summary__text bold">
        {{ t('cart.total') }}
      </div>
      <div class="cart-summary__text bold">
        {{ formatPrice(basketInfo.costs.total_payable) }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/styling.scss';
@import '@/assets/css/mixins/breakpoints-up.scss';

.cart-summary {
  display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &__line {
      display: flex;
      justify-content: space-between;
    }

    &__text {
      .gradient {
        @include gradient-text;
      }
    }
}
</style>
