<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

type Props = {
  average: number
  amount: number
}

const props = defineProps<Props>()
</script>

<template>
  <div class="review-stars">
    <div class="review-stars__stars">
      <div v-for="i in 5"
        :key="i"
        class="review-stars__star"
        :class="{
          'review-stars__star--half': props.average < i - 0.25 && props.average > i - 0.75,
          'review-stars__star--inactive': props.average < i - 0.25
        }"
      />
    </div>
    {{ t('terms.reviews', [props.amount]) }}
  </div>
</template>

<style lang="scss" scoped>
.review-stars {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.5rem;

  &__stars {
    display: flex;
    gap: -0.25rem;
  }

  &__star {
    color: var(--color-neutral-tertiary);
    background-image: url('/assets/img/icons/star-fill.svg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 1rem;
    width: 1rem;
  }

  &__star--inactive {
    background-image: url('/assets/img/icons/star-line.svg');
  }

  &__star--half {
    background-image: url('/assets/img/icons/star-half-line.svg');
  }
}
</style>
