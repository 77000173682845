<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Icon from '@/components/partials/Icon.vue'
import { formatPrice } from '@/plugins/globals/filters'
import { useStore } from '@/stores'

const store = useStore()

const { t } = useI18n()

type Props = {
  amount: number
  product: any // TODO type
  image: string
  withImage: boolean
}

const props = withDefaults(defineProps<Props>(), {
  amount: 1,
  product: () => {},
  image: '',
  withImage: false,
})

const emit = defineEmits(['changeAmount','addInstructions'])

const changeAmount = (direction: number) => {
  emit('changeAmount', {
    hash: props.product.hash,
    amount: props.amount + direction,
  })
}

const hasDiscount = computed(() => props.product.discount > 0)

const allowsInstructions = computed(() => {
  const currentEstablishment = store.getters['establishment/establishment']
  return currentEstablishment?.instructions_mode === 'once_and_per_item'
})
</script>

<template>
  <div class="cart-item"
       :class="{ 'cart-item--has-discount': hasDiscount }"
       data-test-id="cart-item"
  >
    <div class="cart-item__content">
      <div v-if="withImage"
           class="cart-item__image"
           :style="{ 'background-image': `url(${image})`}"
      />
      <div class="cart-item__text">
        <div class="cart-item__title">
          <span data-test-id="cart-product-amount">{{ amount }}x</span> {{ product.title }}
        </div>
        <div class="cart-item__subtitle"
             data-test-id="selected-attributes"
             v-if="product.subtitle">
          {{ product.subtitle }}
        </div>
        <div class="cart-item__remark" data-test-id="remark-button" @click="emit('addInstructions')" v-if="allowsInstructions">
          <span>{{ product.instructions ?? t('cart.addremark') }}</span>
          <Icon name="pencil" v-if="product.instructions" />
        </div>
      </div>
      <div class="cart-item__prices">
        <div data-test-id="cart-item-original-price"
             class="cart-item__original-price"
             v-if="product.price_before_discount">
          {{ formatPrice(product.price_before_discount) }}
        </div>
        <div data-test-id="cart-item-discounted-price"
             class="cart-item__discounted-price"
             v-if="product.price_after_discount">
          {{ formatPrice(product.price_after_discount) }}
        </div>
      </div>
    </div>
    <div class="cart-item__amount">
      <div class="cart-item__line" />
      <div class="cart-item__amount-buttons">
        <div class="cart-item__amount-button"
             data-test-id="cart-product-amount-decrement-button"
             @click="changeAmount(-1)"
        >
          <div>
            <Icon name="minus" />
          </div>
        </div>
        <div class="cart-item__amount-button"
             data-test-id="cart-product-amount-increment-button"
             @click="changeAmount(1)"
        >
          <div>
            <Icon name="plus" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/styling.scss';
@import '@/assets/css/mixins/breakpoints-up.scss';

.cart-item {
  $self: &;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &__content {
    display: flex;
    gap: 1rem;
  }

  &__image {
    width: 3rem;
    height: 3rem;
    flex: 0 0 3rem;
    border-radius: 0.5rem;
    background-color: #efefef;
    background-size: cover;
    background-position: center center;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__remark {
    font-weight: 700;
    line-height: 125%;
    font-size: 0.875rem;

    //hide part of the text if it's too long
    span{
      float:left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 13rem;
      margin-right:5px;
    }

    cursor:pointer;
    &:hover{
      opacity:0.7;
    }
  }

  &__title {
    font-weight: 500;
  }

  &__subtitle {
    font-weight: 300;
    color: #9297A4;
  }

  &__prices {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__discounted-price {
    width: max-content;
  }

  &__original-price {
    display: none;
    width: max-content;
  }

  &__amount {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__amount-buttons {
    display: flex;
    gap: 0.5rem;
    margin-right: -1%;
  }

  &__amount-button {
    width: 1.75rem;
    height: 1.75rem;
    position: relative;

    div {
      z-index: 2;
      position: relative;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 99999px;
      background: #fff;
      border: 1px solid #DEE0E4;
      cursor: pointer;
    }

    &:deep(.b-icon-icon),
    &:deep(.b-icon-icon svg) {
      width: 100%;
      height: 100%;
    }

    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;
      border-radius: 9999px;
      width: calc(100% + 1rem);
      height: calc(100% + 1rem);
      @include gradient-button($new-gradient-brand-main-10);
      transform: scale(0.5);
      transition: all 0.2s;
    }

    &:hover {
      div {
        border-color: var(--color-brand-primary-50);
      }

      &:before {
        transform: scale(1);
      }
    }
  }

  &__line {
    border-top: 1px dashed var(--color-neutral-tertiary);
    flex: 1;
  }

  &--has-discount {
    #{$self} {
      &__discounted-price {
        @include gradient-text;
        font-weight: 700;
      }

      &__original-price {
        color: var(--color-neutral-primary-50);
        text-decoration: line-through;
        display: block;
      }
    }
  }
}
</style>
