<template>
  <div :class="[ 'loading-wrapper', { 'loading-wrapper--sneaky': sneaky } ]">
    <div ref="toBeLoaded" :class="{ 'loading-wrapper__hidden': loading }">
      <slot />
    </div>
    <transition :name="animation">
      <div v-if="loading && !sneaky" class="loading-wrapper__placeholder">
        <LoadingRow v-for="(row, i) in rows"
                    :key="i"
                    class="loading-wrapper__row"
                    :container-height="row.height"
                    :container-width="row.width"
                    :rounded="rounded"
                    :cols="cols"
                    :dynamic-styles="row.style || {}" />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'
import LoadingRow from '@/components/partials/Loading/LoadingRow.vue'

type Props = {
  animation?: string
  loading?: boolean
  sneaky?: boolean
  rounded?: boolean
  width?: string
  height?: string
  maxWidth?: number
  maxHeight?: number
  cols?: number[]
  multilineText?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  animation: '',
  rounded: true,
})

const getTextStylingInPx = (target) => {
  const fontSize = parseFloat(getComputedStyle(target)['font-size']) // returns size in px
  const lineHeightPercentage =
    (parseFloat(getComputedStyle(target)['line-height']) / fontSize) * 100 // line-height in % relative to font size

  const lineHeight = (lineHeightPercentage / 100) * fontSize
  const marginTop = lineHeight - fontSize

  return {
    fontSize,
    lineHeight,
    marginTop,
  }
}

let resizeObserver: ResizeObserver | null = null

type Row = {
  width: string
  height: string
  style?: Record<string, string>
}

const rows = ref<Row[]>([])

const toBeLoaded = ref<HTMLElement | null>(null)

onMounted(() => {
  // TODO: create support for both these props. Need to be able to parse rem, px, %, etc.
  if (props.multilineText && props.height) {
    // eslint-disable-next-line no-console
    console.warn('[LoadingWrapper]: props multiline-text and height cannot be set together.')
  }

  resizeObserver = new ResizeObserver((entries) => {
    if (!props.loading) return // no need to do stuff when the loading is done

    for (const entry of entries) {
      const { width, height } = entry.contentRect

      const hasRelativeValue = (p) => !!(p && !p.includes('px'))

      const widthIsRelative = hasRelativeValue(props.width)
      const heightIsRelative = hasRelativeValue(props.height)

      const clampedWidth = props.maxWidth === undefined ? width : width > props.maxWidth ? props.maxWidth : width
      const clampedHeight = props.maxHeight === undefined ? height : height > props.maxHeight ? props.maxHeight : height

      const dynamicWidth = widthIsRelative ? props.width : clampedWidth

      const dynamicHeight =
        heightIsRelative || (!props.multilineText && props.height)
          ? props.height
          : clampedHeight

      // reset all rows
      rows.value = []

      if (props.multilineText && !props.height) {
        // get the target for the font size calculations
        const target =
          entry.target.querySelector('[data-multiline-text-target]') ||
          entry.target

        const { fontSize, marginTop, lineHeight } = getTextStylingInPx(target)

        const lines = Math.round(dynamicHeight / lineHeight)
        const safety = 6

        for (let i = 0; i < lines; i += 1) {
          rows.value.push({
            width: `${dynamicWidth}px`,
            height: `${fontSize + safety}px`,
            style: {
              'margin-top': `${i > 0 ? marginTop - safety : safety / 2}px`,
            },
          })
        }
      } else {
        rows.value.push({
          width: widthIsRelative ? props.width : `${dynamicWidth}px`,
          height: heightIsRelative ? props.height : `${dynamicHeight}px`,
        })
      }
    }
  })

  if (toBeLoaded.value !== null) {
    resizeObserver.observe(toBeLoaded.value)
  }
})

onBeforeUnmount(() => {
  if (resizeObserver !== null) {
    resizeObserver.disconnect()
    resizeObserver = null
  }
})
</script>

<style lang="scss" scoped>
.loading-wrapper {
  $self: &;
  position: relative;

  &__row {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__hidden {
    opacity: 0;
  }

  // Uncomment for debugging
  // &__hidden,
  // &__placeholder {
  //   opacity: 0.5;
  // }

  // looks like dead code, but may come in handy when debugging
  &--sneaky {
    #{$self}__hidden {
      opacity: 0;
    }
  }
}
</style>
