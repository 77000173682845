<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Dropdown from '@/components/partials/Inputs/Dropdown.vue'
import { DistributionType } from '@/http/models/Establishment'

type Props = {
  modelValue: DistributionType
  distributionTypes?: DistributionType[]
  layout?: string
}

const props = withDefaults(defineProps<Props>(), {
  // TODO remove this default value, DistributionTypeDropdown should be wrapped in a v-if if establishment is null
  distributionTypes: () => [ DistributionType.Delivery, DistributionType.TakeAway ],
})

const emit = defineEmits(['distChange'])

const { t } = useI18n()

const key = ref(0)

const distributionType = computed({
  get: () => props.modelValue,
  set: async val => {
    emit('distChange', val)

    // im not proud if it, but it works
    await nextTick()
    key.value += 1
  }
})

const items = computed(() => {
  const available = (distributionType: DistributionType) => props.distributionTypes.includes(distributionType)

  const delivery = {
    value: DistributionType.Delivery,
    available: available(DistributionType.Delivery),
    selected: distributionType.value === DistributionType.Delivery,
  }

  const takeaway = {
    value: DistributionType.TakeAway,
    available: available(DistributionType.TakeAway),
    selected: distributionType.value === DistributionType.TakeAway,
  }

  return [
    {
      ...delivery,
      label: delivery.available ? t('distribution-toggle.delivery') : t('distribution-toggle.delivery-not-available'),
    },
    {
      ...takeaway,
      label: takeaway.available ? t('distribution-toggle.takeaway') : t('distribution-toggle.takeaway-not-available'),
    },
  ]
})
</script>

<template>
  <Dropdown :items="items" v-model="distributionType" :layout="layout" :key="key" />
</template>
