<script setup lang="ts">
import { computed, ref, watch, onMounted, useAttrs } from 'vue'

defineOptions({
  inheritAttrs: true,
})

const attrs = useAttrs()

type Props = {
  items: Array<{ value: string | number, label: string, selected: boolean, available: boolean }>
  disabled?: boolean
  modelValue: string | number
  layout?: 'square' | 'borderless'
}

const props = withDefaults(defineProps<Props>(), {
  layout: 'square',
})

const emit = defineEmits(['update:modelValue'])

const selectList = ref<HTMLSelectElement | null>(null)

const val = computed({
  get: () => props.modelValue,
  set: val => {
    setWidth()

    emit('update:modelValue', val)
  },
})

// todo: find a proper way to do this
// after the font size is changed, the width of the select element is not updated
const setWidth = () => {
  if (props.layout !== 'borderless') return
  if (selectList.value == null || selectList.value.options === null) {
    return
  }

  const selectedOption = (selectList.value)?.options[(selectList.value)?.selectedIndex]
  if (selectedOption == null) {
    return
  }

  let fontSize = window.getComputedStyle(selectList.value, null).getPropertyValue('font-size')
  let extraSpace = (48 * (parseFloat(fontSize) * 0.075))
  let selectedValueWidth = extraSpace + measureTextWidth(selectedOption.textContent ?? '')
  selectList.value.style.width =  `${selectedValueWidth}px`
}

const measureTextWidth = (text: string) => {
  if (selectList.value == null) {
    return 0
  }
  const span = document.createElement('span')
  span.style.visibility = 'hidden'
  span.style.position = 'absolute'
  span.style.whiteSpace = 'nowrap'
  span.style.font =  window.getComputedStyle(selectList.value, null).font
  span.style.fontSize = window.getComputedStyle(selectList.value, null).getPropertyValue('font-size')
  span.textContent = text
  document.body.appendChild(span)
  const width = span.offsetWidth
  document.body.removeChild(span)
  return width
}

const options = computed(() => props.items ?? [])

watch(options, () => {
  if (props.layout !== 'borderless') return

  // run after option values are changed
  setTimeout(() => {
    setWidth()
  }, 100)
})

onMounted(() => {
  if (props.layout !== 'borderless') return

  setWidth()
})
</script>

<template>
  <div>
    <select v-taptic="'selection'"
            class="select"
            :class="{ 'select__borderless': props.layout === 'borderless' }"
            :disabled="disabled"
            v-bind="attrs"
            data-hj-allow
            v-model="val"
            ref="selectList">
      <option v-for="(option, index) in items"
              :key="`${option.value}-${index}`"
              :value="option.value"
              :selected="option.selected"
              :disabled="!option.available">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/interactions.scss';

.select {
  padding: 0.75rem 2rem 0.75rem 1rem;
  margin: 0;
  background-color: white;
  border: 1px solid var(--color-neutral-quaternary);
  border-radius: 0.5rem;
  width: 100%;
  line-height: 1.4;
  -webkit-appearance: none;
  background-image: url('/assets/img/icons/arrow-down-s-line.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;
  color: var(--color-neutral-primary);

  &__borderless{
    border: unset;
    font-weight: 700;
    background-color: transparent;
    margin-left:0.8rem;
    padding-right:2rem;
    width:auto;
    height:100%;
  }

  &:disabled {
    background-color: #f7f7f7;
    cursor: not-allowed;
  }

  &:focus:not(.select__borderless) {
    outline: none;
    border: 1px solid var(--color-brand-primary);
  }

  @include hover {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
