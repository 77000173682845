<template>
  <div class="free-delivery-notice"
       data-hj-allow
  >
    <template v-if="due > 0">
      <Icon name="scooter-line" />
      {{ t('free-delivery-notice.text', { price }) }}
    </template>
    <template v-else>
      <Icon name="discount" /> {{ t('free-delivery-notice.meets-minimum') }}
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Icon from '@/components/partials/Icon.vue'
import { formatPrice } from '@/plugins/globals/filters'

const props = defineProps({
  due: { type: Number, required: true },
})

const { t } = useI18n()

const price = computed(() => formatPrice(props.due))
</script>
