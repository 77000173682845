<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/stores'
import TextArea from '@/components/partials/Inputs/TextArea.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import Headline from '@/components/partials/Headline.vue'
import ModalNuevo from '@/components/Modals/ModalNuevo/ModalNuevo.vue'
import type { Establishment } from '@/http/models/Establishment'
import type { BasketProduct } from '@/types/BasketProduct'

const props = defineProps<{
  establishment: Establishment
  product: BasketProduct
  open: boolean
}>()

const emit = defineEmits(['close'])

const store = useStore()

const instructions = ref('')

instructions.value = props.product.instructions ?? ''

async function updateInstructions() {
  await store.dispatch('cart/setInstructions', {
    hash: props.product.hash,
    instructions: instructions.value,
  })

  instructions.value = ''

  emit('close')
}

const { t } = useI18n()
</script>

<template>
  <ModalNuevo class="instructions-modal"
              data-test-id="product-remark-modal"
              :open="open"
              @close="emit('close')">
    <template #modal-header>
      <Headline class="instructions-modal__title">
        {{ t('cart.addremarkto', { product: props.product.title }) }}
      </Headline>
    </template>

    <TextArea v-model="instructions"
              grow
              data-test-id="product-remark-textarea"
              :placeholder="establishment.specialInstructionPlaceholder ?? t('cart.remarkplaceholder')"
              class="checkout-form-instructions__text-area" />

    <template #modal-bottom>
      <Button align-right
              data-test-id="remark-modal-save"
              @click="updateInstructions">
        {{ t('buttons.save') }}
      </Button>
    </template>
  </ModalNuevo>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/styling.scss';
@import '@/assets/css/mixins/breakpoints-up.scss';

.instructions-modal {
  &__title {
    font-weight: 700;
    font-size: 1.2rem;
    padding: 2rem 2rem 0;
  }
}
</style>
